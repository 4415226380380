import { User } from "@/models/users/User";
import store from "@/store/index.js";

export class FriendshipInvitation {
  user = null;
  is_auth_sender = false;
  created_at = "";

  constructor(obj) {
    try {
      this.user = new User(obj.user);
      this.is_auth_sender = obj.is_auth_sender;
      this.created_at = obj.created_at;
    } catch (err) {
      console.error(err);
    }
  }

  // return true if auth user is the sender of the friend request
  get is_sender() {
    return this.is_auth_sender;
  }

  async accept() {
    let payload = {
      user_id: this.user.id,
    };

    let res = await store.dispatch(
      "users/accept_friendship_invitation",
      payload
    );

    if (res._status === 200) {
      store.dispatch("users/fetch_friends", { root: true });
      store.commit("app/set_alert", {
        type: "success",
        title: "views.subscriptions.success",
        text: "resources.friends.friendship_confirmed",
      });
    } else {
      store.commit("app/set_alert", { type: "error", title: "toasters.error" });
    }
  }

  async reject() {
    let payload = {
      user_id: this.user.id,
    };

    let res = await store.dispatch(
      "users/delete_friendship_invitation",
      payload
    );

    if (res._status === 200) {
      store.commit("app/set_alert", {
        type: "success",
        title: "views.subscriptions.success",
        text: "resources.friends.friendship_rejected",
      });
    } else {
      store.commit("app/set_alert", { type: "error", title: "toasters.error" });
    }
  }

  async withdraw() {
    let payload = {
      user_id: this.user.id,
    };

    let res = await store.dispatch(
      "users/delete_friendship_invitation",
      payload
    );

    if (res._status === 200) {
      store.commit("app/set_alert", {
        type: "success",
        title: "views.subscriptions.success",
        text: "resources.friends.friendship_withdrawed",
      });
    } else {
      store.commit("app/set_alert", { type: "error", title: "toasters.error" });
    }
  }
}
