export class Worksheet {
  constructor(obj) {
    this.id = obj.id;

    this.is_aborted = obj.is_aborted;
    this.is_archived = obj.is_archived;
    this.is_finished = obj.is_finished;

    this.options = obj.options;
    this.categories = obj.categories ? obj.categories : [];

    this.question_count = obj.question_count;
    this.question_source = obj.question_source;

    this.round_count = obj.options.category_ids.length || obj.round_count;
    this.score = obj.score ? obj.score : 0;

    this.turn_id = obj.turn_id;
    this.user_count = obj.user_count;
    this.progress = obj.progress;

    this.created_at = obj.created_at ? new Date(obj.created_at) : null;
    this.updated_at = obj.updated_at ? new Date(obj.updated_at) : null;

    this.type = "worksheet";
  }
}
