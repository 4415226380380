export const set_worksheets = (state, worksheets) =>
  (state.worksheets = worksheets);
export const set_worksheet = (state, worksheet) =>
  (state.worksheet = worksheet);
export const set_worksheet_rounds = (state, rounds) =>
  (state.worksheet_rounds = rounds);
export const set_current_category = (state, category) =>
  (state.current_category = category);
export const set_current_question_id = (state, id) =>
  (state.current_question_id = id);
export const set_current_round_id = (state, id) =>
  (state.current_round_id = id);
/* export const set_next_question_id = (state, id) => state.next_question_id = id;
 */
export const set_navigation_visible = (state, isVisible) =>
  (state.navigation_visible = isVisible);
/* export const set_current_progress = (state, current_progress) => state.current_progress = current_progress; */
export const trigger_progress = (state, isTriggered) =>
  (state.progress_triggered = isTriggered);
