import API from "@/plugins/servers/Api.js";
import { Achievement } from "@/models/achievements/Achievement";

export const fetch_achievements = async ({ commit }) => {
  try {
    let [res] = await API.get("/achievements/");
    if (res._status === 200) {
      let achievements = res.achievements.map((achievement) => {
        return new Achievement(achievement);
      });
      commit("set_achievements", achievements);
    } else return null;
  } catch (err) {
    return err;
  }
};

export const fetch_achievement = async ({ commit }, achievementId) => {
  try {
    let [res] = await API.get(`/achievements/${achievementId}`);
    if (res) {
      commit("set_achievement", new Achievement(res.achievement));
    } else return null;
  } catch (err) {
    return err;
  }
};
