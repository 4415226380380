import axios from "axios";

let MEDIAAPI_PREFIX = process.env.VUE_APP_MEDIA_SERVER_URL + "/";

/* if (process.env.NODE_ENV == 'development') {
	MEDIAAPI_PREFIX = '/media/';
} */
axios.defaults.withCredentials = false;

export default {
  async get(url, config = { responseType: "blob" }) {
    try {
      let res = await axios.get(
        url.replace("/media/", MEDIAAPI_PREFIX),
        config
      );
      return res;
    } catch (e) {
      console.log("Error in @/services/mediaapi.js -> default.get()", e);
      return null;
    }
  },
  async post(url, payload, config = {}) {
    try {
      //let res = await axios.post('https://mdn.p3l.io/' + url, payload, config);
      let res = await axios.post(MEDIAAPI_PREFIX + url, payload, config);
      return res.data;
    } catch (e) {
      console.log("Error in @/services/mediaapi.js -> default.post()", e);
      return null;
    }
  },
  async put(url, payload, config = {}) {
    try {
      let res = await axios.put(MEDIAAPI_PREFIX + url, payload, config);
      return res.data;
    } catch (e) {
      console.log("Error in @/services/mediaapi.js -> default.put()", e);
      return null;
    }
  },
  async patch(url, payload, config = {}) {
    try {
      let res = await axios.patch(MEDIAAPI_PREFIX + url, payload, config);
      return res.data;
    } catch (e) {
      console.log("Error in @/services/mediaapi.js -> default.patch()", e);
      return null;
    }
  },
  async delete(url, config = {}) {
    try {
      let res = await axios.delete(MEDIAAPI_PREFIX + url, config);
      return res.data;
    } catch (e) {
      console.log("Error in @/services/mediaapi.js -> default.delete()", e);
      return null;
    }
  },
};
