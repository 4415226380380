<template>
  <div>
    <v-badge
      v-if="notification_count_unseen > 0"
      overlap
      right
      top
      offset-y="14"
      color="error"
      class="mr-2"
    >
      <template #badge>
        <span class="badge_count">{{ count }}</span>
      </template>
      <PIcon
        icon="notification-active"
        :color="color"
        :class="{ transform: iconSet && iconSet === 'mdi' }"
      />
    </v-badge>

    <PIcon
      v-if="notification_count_unseen === 0"
      icon="notification"
      :color="color"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NotificationIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "white",
    },
  },
  computed: {
    ...mapGetters("notifications", {
      notification_count_unseen: "get_notification_count_unseen",
    }),
    count() {
      return this.notification_count_unseen < 10
        ? this.notification_count_unseen
        : "9+";
    },
    iconSet() {
      return process.env.VUE_APP_ICON_SET;
    },
  },
};
</script>

<style lang="scss">
.transform {
  transform: rotate(-15deg);
}
.badge_count {
  font-weight: 800;
  font-size: 10px;
}
</style>
