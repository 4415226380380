<template>
  <div :style="`height:${height}`" class="d-flex align-center justify-center">
    <v-fade-transition leave-absolute>
      <div class="loading-icon" />
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: "AppLoading",
  props: {
    height: {
      type: String,
      required: false,
      default: "50vh",
    },
  },
};
</script>

<style lang="scss">
.loading-icon {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.loading-icon:after {
  content: "";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid var(--v-primary-base);
  border-color: var(--v-primary-base) transparent var(--v-primary-base)
    transparent;
  animation: loading-icon 1.2s linear infinite;
}
@keyframes loading-icon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
