import { QuizResult } from "@/models/quiz/results/QuizResult";

export class QuizSoloResult extends QuizResult {
  constructor(obj) {
    super(obj);
    this.type = "solo";
    this.user_count = 1;

    this.rounds = this.generateRounds(obj);
  }

  generateRounds(obj) {
    if (!this.quiz_rounds) {
      this.score.total = obj.score;
      return [];
    }
    let rounds = [...this.quiz_rounds];
    rounds.forEach((round) => {
      var score_user = 0;
      var score_time = 0;

      if (round.answers[this.user.id]) {
        round.answers[this.user.id].forEach((answer) => {
          score_user += answer.blix_correct;
          score_time += answer.blix_time;
        });
      }

      round.score_user = score_user;
      round.score_time = score_time;
    });
    return rounds;
  }
}
