export const state = {
  register_userinfo: null,
  register_code: "",
  register_invitation_token: "",
  register_custom_fields: null,
  register_assign_structures: [],
  register_select_structures: [],
  current_user: null,
  auth_sources: null,
};
