import i18n from "@/i18n.js";

/**
 * @typedef Nugget
 * @property {number} Nugget.id
 * @property {string} Nugget.title
 * @property {1|0} Nugget.is_active
 * @property { 'text'|'video'} Nugget.type
 * @property {{id: number, name: string}} Nugget.category
 * @property {string} Nugget.image
 * @property {string} Nugget.source
 * @property {string} Nugget.media_uid
 * @property {string} Nugget.content
 * @property {string[]} Nugget.links
 * @property {string} Nugget.description
 * @property {boolean} Nugget.is_liked
 * @property {boolean} Nugget.is_favorite
 * @property {number} Nugget.like_count
 * @property {number} Nugget.level
 * @property {Date|string} Nugget.created_at
 */

/**
 * @type {Nugget}
 */

import { MEDIA_SERVER_URL } from "@/plugins/servers/Api.js";
import store from "@/store/index.js";

export class Nugget {
  id = -1;
  title = "";
  description = "";
  is_active = 0;
  type = "";
  category = { id: -1, name: "" };
  image = "";
  source = "";
  media_uid = "";
  content = "";
  links = [];
  is_liked = false;
  is_favorite = false;
  like_count = 0;
  level = 0;
  created_at = "";

  constructor(obj) {
    try {
      this.id = parseInt(obj.id);
      this.title = obj.title;
      this.description = obj.description;
      this.is_active = obj.is_active;
      this.type = obj.ng_format || obj.type;
      this.category = obj.category;
      this.image = obj.image;
      this.image_hash = obj.image_hash;
      this.images = obj.images;
      this.images_hash = obj.images_hash;
      this.source = obj.ng_source;
      this.media_uid = obj.media_uid;
      this.content = obj.content;
      this.links = !obj.links || obj.links[0] == "" ? [] : obj.links;
      this.is_liked = obj.is_liked;
      this.is_favorite = obj.is_favorite;
      this.like_count = obj.like_count;
      this.level = obj.level;
      this.created_at = obj.created_at ? new Date(obj.created_at) : new Date();
    } catch (err) {
      console.error(err);
    }
  }

  get imageUrl() {
    if (!this.image_hash) return "/cdn/images/default.jpg";
    return `${MEDIA_SERVER_URL}/i/${this.image_hash}`;
  }

  async getVideoUrl() {
    var payload = { nugget_id: this.id, language: i18n.locale };
    const token = await store.dispatch("nuggets/fetch_nugget_token", payload);
    return `${MEDIA_SERVER_URL}/v/${token}`;
  }

  async like() {
    let payload = {
      id: this.id,
      user_id: store.getters["auth/get_current_user"].id,
      property: "is_liked",
      value: !this.is_liked,
    };

    let res = await store.dispatch("nuggets/patch_nugget", payload);

    if (res) {
      this.is_liked = !this.is_liked;
      this.is_liked ? this.like_count++ : this.like_count--;
      store.commit("app/set_alert", {
        type: "success",
        title: "views.subscriptions.success",
        text: "resources.nuggets.toasters.likeToggle." + String(this.is_liked),
      });
    } else {
      store.commit("app/set_alert", {
        type: "error",
        title: "resources.toasters.error",
      });
    }
  }
  async favorite() {
    let payload = {
      id: this.id,
      user_id: store.getters["auth/get_current_user"].id,
      property: "is_favorite",
      value: !this.is_favorite,
    };

    let res = await store.dispatch("nuggets/patch_nugget", payload);

    if (res) {
      this.is_favorite = !this.is_favorite;
      store.commit("app/set_alert", {
        type: "success",
        title: "views.subscriptions.success",
        text:
          "resources.nuggets.toasters.favoriteToggle." +
          String(this.is_favorite),
      });
    } else {
      store.commit("app/set_alert", {
        type: "error",
        title: "resources.toasters.error",
      });
    }
  }

  share() {
    navigator.clipboard.writeText(this.getUrl()).then(() => {
      store.commit("app/set_alert", {
        type: "success",
        title: "views.subscriptions.success",
        text: "resources.nuggets.toasters.linkCopied",
      });
    });
  }

  getUrl() {
    const { protocol, host } = document.location;
    const url = `${protocol}//${host}/nuggets/${this.id}`;
    return url;
  }
}
