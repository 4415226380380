<template>
  <v-dialog
    v-if="showDialog"
    :value="showDialog"
    max-width="560"
    @keydown.esc="closeDialog()"
    @click:outside="closeDialog()"
  >
    <v-card color="white" max-width="560">
      <v-card-title class="overline primary--text">
        {{ $t("resources.friends.delete_friendship") }}
      </v-card-title>
      <v-card-text>
        <div
          v-html="
            $t('resources.friends.delete_friendship_info', {
              name: user.username,
            })
          "
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text small @click="closeDialog()">
          {{ $t("views.questions.cancel") }}
        </v-btn>
        <v-btn color="error" text small @click="deleteFriendship">
          {{ $t("resources.friends.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { User } from "@/models/users/User";

export default {
  name: "RemoveFriendshipDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    user: {
      type: User,
      required: true,
    },
  },
  computed: {
    showDialog() {
      return this.visible;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    deleteFriendship() {
      this.user.remove_friendship();
      this.closeDialog();
    },
  },
};
</script>

<style></style>
