export const get_register_userinfo = (state) => state.register_userinfo;
export const get_register_code = (state) => state.register_code;
export const get_register_invitation_token = (state) =>
  state.register_invitation_token;

export const get_register_custom_fields = (state) =>
  state.register_custom_fields;
export const get_register_assign_structures = (state) =>
  state.register_assign_structures;
export const get_register_select_structures = (state) =>
  state.register_select_structures;

export const get_current_user = (state) => state.current_user;
export const get_auth_sources = (state) => state.auth_sources;
