<template>
  <v-alert
    v-model="showCookieAlert"
    class="cookie-policy-alert"
    prominent
    color="primary"
    icon="mdi-information"
    dark
    dense
    :class="{ 'cookie-policy-mb': bottom_nav.visible }"
  >
    <v-row align="center" dense>
      <v-col cols="12" sm="10">
        {{ $t("cookies.cookiePolicyAgreement") }}
        <br />
        <b
          style="cursor: pointer"
          @click="showCookiePolicy = !showCookiePolicy"
          >{{ $t("cookies.viewCookies") }}</b
        >

        <p v-if="showCookiePolicy">
          <br />
          {{ $t("cookies.cookiePolicy") }}
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        :class="{ 'text-right': $vuetify.breakpoint.smAndUp }"
      >
        <v-btn color="success" @click="acceptCookies()">
          {{ $t("cookies.acceptCookies") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CookiePolicy",
  data() {
    return {
      showCookieAlert: false,
      showCookiePolicy: false,
    };
  },
  computed: {
    ...mapGetters("app", { bottom_nav: "get_bottomnavigation_settings" }),
  },
  beforeMount() {
    if (!localStorage.respondedCookiePolicy) {
      this.showCookieAlert = true;
    }
  },
  methods: {
    acceptCookies() {
      this.showCookieAlert = false;
      localStorage.respondedCookiePolicy = true;
    },
  },
};
</script>

<style lang="scss">
.cookie-policy-alert {
  position: fixed !important;
  bottom: 16px;
  left: 0;
  margin: 0 20px;
  width: calc(100% - 40px);
  z-index: 10000;
}
.cookie-policy-mb {
  bottom: 0;
  margin-bottom: 64px !important;
}
</style>
