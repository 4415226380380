import axios from "axios";

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = localStorage.getItem("token");
  config.validateStatus = function (status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  };
  return config;
});

const env = process.env;

const request = function (packet) {
  //Interceptor for any axios request in the whole application

  return axios(packet)
    .then((response) => {
      if (packet.headers["Content-Type"] === "application/pdf")
        return [response];
      response.data._status = response.status;
      return [response.data];
    })
    .catch((err) => {
      if (err && err.response && err.response.data) {
        throw err.response.data.error;
      }
      throw err.error;
    });
};

export const setAuthHeader = () => {
  const token = localStorage.getItem("token");
  axios.interceptors.request.use(function (config) {
    if (token) {
      config.headers.Authorization = token;
    } else {
      config.headers.Authorization = null;
    }
    return config;
  });
};

// This is just a more complicated version of JSON.stringify()
function getDataFromObject(data) {
  let urlAdditions = "";

  if (Object.keys(data).length) {
    Object.keys(data).forEach((key) => {
      if (typeof data[key] == "object") {
        urlAdditions += `&${key}=${encodeURIComponent(
          JSON.stringify(data[key])
        )}`;
      } else if (Array.isArray(data)) {
        // TODO: Not sure if this is how it consumes the data...
        urlAdditions += `&${key}=[${encodeURIComponent(data[key].toString())}]`;
      } else {
        urlAdditions += `&${key}=${encodeURIComponent(data[key])}`;
      }
    });
  }

  return urlAdditions;
}

export default class Api {
  constructor(vm = {}) {
    Object.assign(this, vm);
  }

  static get(uri, apitoken = null, data = {}) {
    if (uri[0] == "/") {
      uri = uri.substr(1);
    }

    if (apitoken) {
      console.log("");
    }

    const packet = {
      data,
      method: "GET",
      //url: `${env.VUE_APP_API_URL}${uri}${apitoken ? `?apitoken=${apitoken}`: '?'}`,
      url: `${env.VUE_APP_API_URL}${uri}`,
      headers: {
        Accept: "application/json",
        withCredentials: true,
        "Allow-Access-Control-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "*",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    };
    if (Object.keys(data).length) {
      packet.url += `${getDataFromObject(data)}`;
    }
    return request(packet);
  }

  static post(uri, apitoken = null, data = {}, params = {}, headers = {}) {
    headers["Accept"] = "application/json";
    headers["Content-Type"] = "application/json";

    if (uri[0] == "/") {
      uri = uri.substr(1);
    }

    let fullUri = `${env.VUE_APP_API_URL}${uri}${
      apitoken ? `?access_token=${apitoken}` : "?"
    }`;

    if (Object.keys(params).length) {
      fullUri += `${getDataFromObject(params)}`;
    }

    const packet = {
      data,
      method: "POST",
      url: fullUri,
      headers: {
        Accept: "application/json",
      },
    };

    return request(packet);
  }

  static patch(uri, apitoken = null, data) {
    if (uri[0] == "/") {
      uri = uri.substr(1);
    }

    const packet = {
      data,
      method: "PATCH",
      url: `${env.VUE_APP_API_URL}${uri}${
        apitoken ? `?access_token=${apitoken}` : "?"
      }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${apitoken}`,
        "Content-Type": "application/json",
      },
    };

    return request(packet);
  }

  static put(uri, apitoken = null, data, filter = {}) {
    if (uri[0] == "/") {
      uri = uri.substr(1);
    }

    const packet = {
      data,
      method: "PUT",
      url: `${env.VUE_APP_API_URL}${uri}${
        apitoken ? `?access_token=${apitoken}` : "?"
      }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${apitoken}`,
        "Content-Type": "application/json",
      },
    };

    if (Object.keys(filter).length) {
      packet.url += `${getDataFromObject(filter)}`;
    }

    return request(packet);
  }

  static delete(uri, apitoken = null, data) {
    if (uri[0] == "/") {
      uri = uri.substr(1);
    }

    const packet = {
      data,
      method: "DELETE",
      url: `${env.VUE_APP_API_URL}${uri}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${apitoken}`,
        "Content-Type": "application/json",
      },
    };

    return request(packet);
  }

  static download(uri, apitoken = null, data = {}) {
    if (uri[0] == "/") {
      uri = uri.substr(1);
    }

    if (apitoken) {
      console.log("");
    }

    const packet = {
      data,
      method: "GET",
      //url: `${env.VUE_APP_API_URL}${uri}${apitoken ? `?apitoken=${apitoken}`: '?'}`,
      url: `${env.VUE_APP_API_URL}${uri}`,
      responseType: "blob",
      headers: {
        //Accept: 'application/json',
        withCredentials: true,
        "Allow-Access-Control-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "*",
        //'Content-Type': content_type ? content_type : 'application/json'
        "Content-Type": "application/pdf",
        //'Response-Type': 'blob'
      },
      credentials: "same-origin",
    };
    if (Object.keys(data).length) {
      packet.url += `${getDataFromObject(data)}`;
    }
    return request(packet);
  }
}

export const MEDIA_SERVER_URL =
  process.env.NODE_ENV === "development"
    ? "/media"
    : process.env.VUE_APP_MEDIA_SERVER_URL;
