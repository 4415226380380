import API from "@/plugins/servers/Api.js";

export const fetch_quests = async ({ commit }) => {
  try {
    let [res] = await API.get("/quests/");
    if (res._status === 200) commit("set_quests", res.quests);
    return res;
  } catch (err) {
    return err;
  }
};

export const patch_quest = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.patch(`/quests/${payload.questId}`, null, payload);
    if (res._status === 200) {
      dispatch("fetch_quests");
    }
    return res;
  } catch (err) {
    return err;
  }
};
