<template>
  <v-form v-model="valid">
    <div class="d-flex">
      <v-file-input
        v-model="files"
        dense
        small-chips
        counter
        multiple
        show-size
        :loading="uploading"
        accept="image/png,image/jpg"
        :rules="rules"
        @change="$emit('pendingUpload', files && files.length > 0)"
        @click:clear="clearForm()"
      >
        <template v-slot:selection="{ text }">
          <v-chip small label :color="upload_success ? 'success' : 'primary'">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>

      <v-btn
        class="ml-4"
        small
        color="primary"
        :text="uploading"
        :disabled="uploadButtonDisabled"
        @click="upload()"
      >
        <span>{{ $t("dialogs.support.upload") }}</span>
        <v-progress-circular
          v-if="uploading"
          class="ml-2"
          size="20"
          width="3"
          indeterminate
        />
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FileUpload",
  data() {
    return {
      valid: true,
      uploading: false,
      upload_success: false,
      files: null,
      uploaded_files: [],
      upload_error: false,
      rules: [
        (files) =>
          !files ||
          !files.some((file) => file.size > 2097152) ||
          this.$t("dialogs.support.upload_max"),
      ],
    };
  },
  computed: {
    uploadButtonDisabled() {
      if (this.files && this.files.length > 0 && !this.uploading && this.valid)
        return false;
      return true;
    },
  },
  mounted() {
    this.clearForm();
  },
  methods: {
    ...mapActions("media", ["upload_file"]),
    async upload() {
      this.upload_success = false;
      this.uploading = true;
      for (const file of this.files) {
        let inputs = new FormData();
        inputs.append("file", file);
        inputs.append("domain", `${process.env.VUE_APP_INSTANCE_NAME}.p3l.app`);
        inputs.append(
          "media_server_url",
          `${process.env.VUE_APP_MEDIA_SERVER_URL}/`
        );
        let res = await this.upload_file(inputs);
        if (res && res._status === 200) {
          this.uploaded_files.push(res.media.uuid);
          this.$notify({
            type: "success",
            title: this.$t("views.subscriptions.success"),
            text: this.$t("dialogs.support.image_upload_success"),
          });
        } else {
          this.$notify({
            type: "error",
            title: this.$t("views.subscriptions.error"),
            text: this.$t("dialogs.support.image_upload_error"),
          });
          this.upload_error = true;
        }
      }
      if (!this.upload_error) {
        this.clearForm();
        this.$emit("uploaded", this.uploaded_files);
      }
      this.$emit("pendingUpload", false);
      this.uploading = false;
      this.upload_success = true;
    },
    clearForm() {
      this.files = null;
      this.upload_files = [];
      this.uploading = false;
      this.upload_success = false;
      this.upload_error = false;
      this.$emit("clear");
    },
  },
};
</script>

<style></style>
