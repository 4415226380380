export const set_avatars = (state, avatars) => {
  state.avatars = avatars;
};

export const set_friends = (state, friends) => {
  state.friends = friends;
};

export const set_friendship_invitations = (state, invitations) => {
  state.friendship_invitations = invitations;
};

export const remove_friendship_invitation = (state, invitation) => {
  state.friendship_invitations = state.friendship_invitations.filter(
    (inv) => inv.user.id !== invitation.user_id
  );
};

export const set_user = (state, user) => {
  state.user = user;
};

export const set_dashboard_data = (state, data) => {
  if (!data) {
    state.dashboard = {
      duels: [],
      journeys: [],
      nuggets: [],
      quest: null,
      ranking: null,
      stats: null,
      content: null,
    };
  }
  state.dashboard = data;
};
//export const set_dashboard_journeys = (state, data) => state.dashboard.journeys = data;
