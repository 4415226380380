<template>
  <v-btn
    fab
    :fixed="$vuetify.breakpoint.smAndDown"
    :absolute="$vuetify.breakpoint.mdAndUp"
    top
    right
    x-small
    :color="color"
    class="mt-1"
    :class="{ margin: $vuetify.breakpoint.mdAndUp }"
    @click="$emit('clicked')"
  >
    <v-icon>mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "MenuCloseButton",
  props: {
    color: {
      type: String,
      required: false,
      default: "secondary",
    },
  },
};
</script>

<style lang="scss">
.margin {
  right: 28px !important;
  top: -16px !important;
}
</style>
