import { Notification } from "@/models/notifications/Notification";
// journey:
// Number id

export class NotificationJourneyStarted extends Notification {
  constructor(obj) {
    super(obj);
    this.journey = obj.journey;
    this.link = { name: "Journey", params: { id: obj.journey.id } };
    this.background_image = "not-journey.png";
    this.no_avatar = true;
  }
}
