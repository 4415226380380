<template>
  <div>
    <v-row
      align="center"
      justify="center"
      class="fade-transition"
      transition="fade-transition"
      style="height: 100%"
    >
      <v-col cols="12">
        <div id="inner" class="loadingio-spinner-dual-ring-5o06faz2azy">
          <div class="ldio-s2zkqfe3v9d">
            <div />
            <div><div /></div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "SectionSpinner",
};
</script>
<style lang="scss">
#inner {
  display: table;
  margin: 0 auto;
}

#outer {
  width: 100%;
}

@keyframes ldio-s2zkqfe3v9d {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-s2zkqfe3v9d div {
  box-sizing: border-box !important;
}
.ldio-s2zkqfe3v9d > div {
  position: absolute;
  width: 51px;
  height: 51px;
  top: 24.5px;
  left: 24.5px;
  border-radius: 50%;
  border: 1px solid #00afd7;
  border-color: #00afd7 transparent #00afd7 transparent;
  animation: ldio-s2zkqfe3v9d 1s linear infinite;
}
.ldio-s2zkqfe3v9d > div:nth-child(2) {
  border-color: transparent;
}
.ldio-s2zkqfe3v9d > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-s2zkqfe3v9d > div:nth-child(2) div:before,
.ldio-s2zkqfe3v9d > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  top: -1px;
  left: 24px;
  background: #00afd7;
  border-radius: 50%;
  box-shadow: 0 50px 0 0 #00afd7;
}
.ldio-s2zkqfe3v9d > div:nth-child(2) div:after {
  left: -1px;
  top: 24px;
  box-shadow: 50px 0 0 0 #00afd7;
}
.loadingio-spinner-dual-ring-5o06faz2azy {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-s2zkqfe3v9d {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-s2zkqfe3v9d div {
  box-sizing: content-box;
}
</style>
