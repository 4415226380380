<template>
  <v-list
    class="overflow-y-auto py-0"
    :class="$vuetify.breakpoint.xsOnly ? 'transparent' : 'white'"
  >
    <v-subheader
      v-if="!hideHeader"
      class="justify-space-between primary white--text"
    >
      <SectionHeader :text="$t('navBar.notifications')" icon-color="white" />
      <v-btn small icon @click="$emit('close')">
        <PIcon icon="close" color="white" />
      </v-btn>
    </v-subheader>
    <v-subheader :class="$vuetify.breakpoint.xsOnly ? 'transparent' : 'white'">
      <v-switch
        v-model="hide_seen"
        dense
        hide-details
        color="primary"
        class="mt-0"
      >
        <template #label>
          <span class="text--caption">{{
            $t("views.notifications.hide_seen")
          }}</span>
        </template>
      </v-switch>
    </v-subheader>

    <v-list-item v-if="visibleNotifications.length === 0" class="text-center">
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ $t("views.notifications.no_notifications") }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <NotificationListItem
      v-for="(notification, i) in visibleNotifications"
      :key="i"
      :notification="notification"
      @close="$emit('close')"
    />

    <v-list-item dense class="transparent">
      <v-list-item-content>
        <v-btn
          color="primary"
          small
          text
          :disabled="
            !notifications ||
            notifications.length === 0 ||
            notification_count_unseen === 0
          "
          @click="markAsSeen()"
        >
          {{ $t("views.notifications.mark_all_as_seen") }}
        </v-btn>
        <v-btn
          color="error"
          small
          text
          class="mt-2"
          :disabled="!notifications || notifications.length === 0"
          @click="deleteNotifications()"
        >
          {{ $t("views.notifications.delete_all") }}
        </v-btn>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SectionHeader from "@/components/layout/SectionHeader.vue";
import NotificationListItem from "@/components/notifications/NotificationListItem";

export default {
  name: "NotificationList",
  components: {
    SectionHeader,
    NotificationListItem,
  },
  props: {
    iconColor: {
      type: String,
      required: false,
      default: "white",
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      hide_seen: false,
    };
  },
  computed: {
    ...mapGetters("notifications", {
      notifications: "get_notifications",
      notification_count_unseen: "get_notification_count_unseen",
    }),
    visibleNotifications() {
      if (!this.notifications) return [];
      let notifications = [...this.notifications];
      if (this.hide_seen)
        notifications = this.notifications.filter(
          (notification) => notification.is_seen == false
        );
      return notifications;
      //return notifications.sort((a,b) => a.updated_at - b.updated_at );
    },
  },
  watch: {
    visible(state) {
      if (state) this.fetchData();
    },
  },
  methods: {
    ...mapActions("notifications", [
      "fetch_notifications",
      "patch_notifications_seen",
      "delete_notifications",
    ]),
    async fetchData() {
      this.loading = true;
      await this.fetch_notifications();
      this.loading = false;
    },
    async markAsSeen() {
      let idsToMark = [];
      this.notifications.forEach((notification) =>
        idsToMark.push(notification.id)
      );
      let payload = { ids: idsToMark };
      this.patch_notifications_seen(payload);
    },
    async deleteNotifications() {
      let idsToDelete = [];
      this.notifications.forEach((notification) =>
        idsToDelete.push(notification.id)
      );
      let payload = { ids: idsToDelete };
      this.delete_notifications(payload);
    },
  },
};
</script>

<style lang="scss"></style>
