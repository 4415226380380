<template>
  <v-list-item
    class="border-bottom pl-1"
    :class="{ 'white mb-3': $vuetify.breakpoint.xsOnly }"
    two-line
    @click="notification.link ? goTo() : null"
  >
    <!-- :class="notification.is_seen ? 'white' : 'grey lighten-3'" -->
    <v-list-item-avatar tile height="64" width="64" class="ma-0 mr-1">
      <v-img
        v-if="notification.background_image"
        width="64"
        height="64"
        style="position: absolute; top: 4; left: 4"
        :src="`/cdn/notifications/${notification.background_image}`"
      />
      <v-avatar
        v-if="!notification.no_avatar"
        size="32"
        :color="
          notification.avatar_border
            ? notification.avatar_border
            : 'primary darken-3'
        "
      >
        <v-img
          v-if="notification.image"
          :src="notification.image"
          :style="
            notification.avatar_border ? `border: 2px solid transparent` : ''
          "
        />
        <span
          v-if="notification.type === 'level_raised'"
          class="text--caption font-weight-bold white--text"
          >{{ notification.level.id }}</span
        >
        <PIcon
          v-if="
            ['quest_unlocked', 'quest_finished'].includes(notification.type)
          "
          color="white"
          small
          icon="check"
        />
      </v-avatar>
    </v-list-item-avatar>

    <!-- ACHIEVEMENT_UNLOCKED -->
    <v-list-item-content v-if="notification.type === 'achievement_unlocked'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.achievement_unlocked.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{
          $t("views.notifications.types.achievement_unlocked.subtitle", {
            stage: notification.achievement.stage,
          })
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- ACHIEVEMENT_UNLOCKEDLOCKED -->

    <!-- DUEL_FINISHED -->
    <v-list-item-content v-if="notification.type === 'duel_finished'">
      <v-list-item-title class="font-weight-bold caption black--text">
        {{
          notification.game.user_roundwins >
          notification.game.opponent_roundwins
            ? $t("views.notifications.types.duel_finished.title_1")
            : $t("views.notifications.types.duel_finished.title_2")
        }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{
          $t("views.notifications.types.duel_finished.subtitle", {
            user: notification.game.user_roundwins,
            opponent: notification.game.opponent_roundwins,
            name: notification.game.opponent.user_name,
          })
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- DUEL_FINISHED -->

    <!-- DUEL_INVITATION -->
    <v-list-item-content v-if="notification.type === 'duel_invitation'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.duel_invitation.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{
          $t("views.notifications.types.duel_invitation.subtitle", {
            name: notification.user.name,
          })
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- DUEL_INVITATION -->

    <!-- DUEL_TURN -->
    <v-list-item-content v-if="notification.type === 'duel_turn'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.duel_turn.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{
          $t("views.notifications.types.duel_turn.subtitle", {
            user: notification.game.user_roundwins,
            opponent: notification.game.opponent_roundwins,
            name: notification.game.opponent.user_name,
          })
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- DUEL_TURN -->

    <!-- FRIENDSHIP_ACCEPTED -->
    <v-list-item-content v-if="notification.type === 'friend_accepted'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.friend_accepted.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{
          $t("views.notifications.types.friend_accepted.subtitle", {
            name: notification.user.name,
          })
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- FRIENDSHIP_ACCEPTED -->

    <!-- FRIENDSHIP_RECEIVED -->
    <v-list-item-content v-if="notification.type === 'friend_invitation'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.friend_invitation.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{
          $t("views.notifications.types.friend_invitation.subtitle", {
            name: notification.user.name,
          })
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- FRIENDSHIP_RECEIVED -->

    <!-- FRIENDSHIP_REJECTED -->
    <v-list-item-content v-if="notification.type === 'friend_rejected'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.friend_rejected.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{
          $t("views.notifications.types.friend_rejected.subtitle", {
            name: notification.user.name,
          })
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- FRIENDSHIP_REJECTED -->

    <!-- JOURNEY_STARTED -->
    <v-list-item-content v-if="notification.type === 'journey_started'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.journey_started.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{ $t("views.notifications.types.journey_started.subtitle") }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- JOURNEY_STARTED -->

    <!-- LEVEL_RAISED -->
    <v-list-item-content v-if="notification.type === 'level_raised'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.level_raised.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{
          $t("views.notifications.types.level_raised.subtitle", {
            level: notification.level.id,
          })
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- LEVEL_RAISED -->

    <!-- QUEST_FINISHED -->
    <v-list-item-content v-if="notification.type === 'quest_finished'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.quest_finished.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{ $t("views.notifications.types.quest_finished.subtitle") }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- QUEST_UNLOCKED -->

    <!-- QUEST_UNLOCKED -->
    <v-list-item-content v-if="notification.type === 'quest_unlocked'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.quest_unlocked.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{ $t("views.notifications.types.quest_unlocked.subtitle") }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- QUEST_UNLOCKED -->

    <!-- QUESTION_ACCEPTED -->
    <v-list-item-content v-if="notification.type === 'question_accepted'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.question_accepted.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{ $t("views.notifications.types.question_accepted.subtitle") }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- QUESTION_ACCEPTED -->

    <!-- QUESTION_REJECTED -->
    <v-list-item-content v-if="notification.type === 'question_rejected'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.question_rejected.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{ $t("views.notifications.types.question_rejected.subtitle") }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- QUESTION_REJECTED -->

    <!-- QUESTION_REVISION -->
    <v-list-item-content v-if="notification.type === 'question_edit'">
      <v-list-item-title class="font-weight-bold text--caption black--text">
        {{ $t("views.notifications.types.question_edit.title") }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--caption">
        {{ $t("views.notifications.types.question_edit.subtitle") }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <!-- QUESTION_REVISION -->

    <!-- REWARD_UNLOCKED -->
    <RewardDialog
      v-if="notification.type === 'reward_unlocked'"
      :show-dialog="showRewardDialog"
      :reward-id="notification.reward.id"
      class="flex-grow-1 pa-0 ma-0"
      @close="closeRewardDialog(notification)"
    >
      <template #trigger>
        <v-list-item-content
          v-if="notification.type === 'reward_unlocked'"
          @click="openRewardDialog()"
        >
          <v-list-item-title class="font-weight-bold text--caption black--text">
            {{ $t("views.notifications.types.reward_unlocked.title") }}
          </v-list-item-title>
          <v-list-item-subtitle class="text--caption">
            {{ $t("views.notifications.types.reward_unlocked.subtitle") }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </RewardDialog>
    <!-- REWARD_UNLOCKED -->

    <v-list-item-action class="align-end justify-end">
      <div class="d-flex">
        <v-btn
          v-if="!notification.is_seen"
          icon
          small
          @click.stop.prevent="markAsSeen()"
        >
          <PIcon small color="primary" icon="alert-variant" />
        </v-btn>
        <v-btn icon small @click.stop.prevent="deleteNotification()">
          <PIcon color="error" icon="delete" />
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions } from "vuex";
import { NotificationAchievementUnlocked } from "@/models/notifications/types/NotificationAchievementUnlocked";
import { NotificationDuelFinished } from "@/models/notifications/types/NotificationDuelFinished";
import { NotificationDuelInvitation } from "@/models/notifications/types/NotificationDuelInvitation";
import { NotificationDuelTurn } from "@/models/notifications/types/NotificationDuelTurn";
import { NotificationFriendshipAccepted } from "@/models/notifications/types/NotificationFriendshipAccepted";
import { NotificationFriendshipReceived } from "@/models/notifications/types/NotificationFriendshipReceived";
import { NotificationFriendshipRejected } from "@/models/notifications/types/NotificationFriendshipRejected";
import { NotificationJourneyStarted } from "@/models/notifications/types/NotificationJourneyStarted";
import { NotificationLevelRaised } from "@/models/notifications/types/NotificationLevelRaised";
import { NotificationQuestFinished } from "@/models/notifications/types/NotificationQuestFinished";
import { NotificationQuestUnlocked } from "@/models/notifications/types/NotificationQuestUnlocked";
import { NotificationQuestionApproved } from "@/models/notifications/types/NotificationQuestionApproved";
import { NotificationQuestionRejected } from "@/models/notifications/types/NotificationQuestionRejected";
import { NotificationQuestionRevision } from "@/models/notifications/types/NotificationQuestionRevision";
import { NotificationRewardUnlocked } from "@/models/notifications/types/NotificationRewardUnlocked";
import RewardDialog from "@/components/rewards/RewardDialog";

export default {
  name: "NotificationListItem",
  components: {
    RewardDialog,
  },
  props: {
    notification: {
      type: [
        NotificationAchievementUnlocked,
        NotificationDuelFinished,
        NotificationDuelInvitation,
        NotificationDuelTurn,
        NotificationFriendshipAccepted,
        NotificationFriendshipReceived,
        NotificationFriendshipRejected,
        NotificationJourneyStarted,
        NotificationLevelRaised,
        NotificationQuestFinished,
        NotificationQuestUnlocked,
        NotificationQuestionApproved,
        NotificationQuestionRejected,
        NotificationQuestionRevision,
        NotificationRewardUnlocked,
      ],
      required: true,
    },
  },
  data() {
    return {
      showRewardDialog: false,
    };
  },
  methods: {
    ...mapActions("notifications", [
      "patch_notifications_seen",
      "delete_notifications",
    ]),
    async markAsSeen() {
      let payload = { ids: this.notification.id };
      this.patch_notifications_seen(payload);
    },
    async deleteNotification() {
      let payload = { ids: this.notification.id };
      this.delete_notifications(payload);
    },
    goTo() {
      this.markAsSeen();
      this.$emit("close");
      if (
        this.notification.link &&
        this.$route.fullPath !== this.notification.link
      ) {
        this.$router.push(this.notification.link);
      } else return false;
    },
    openRewardDialog() {
      this.showRewardDialog = true;
    },
    closeRewardDialog(notification) {
      if (!notification.is_seen) this.markAsSeen();
      this.showRewardDialog = false;
    },
  },
};
</script>

<style lang="scss">
.border-bottom {
  border-bottom: 1px solid var(--v-background-darken1);
}
</style>
