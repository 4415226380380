export const set_nuggets = (state, nuggets) => {
  state.nuggets = nuggets;
};
export const set_nugget = (state, nugget) => {
  state.nugget = nugget;
};
export const set_related_nuggets = (state, nuggets) => {
  state.related_nuggets = nuggets;
};
export const set_quiz_nuggets = (state, nuggets) => {
  state.quiz_nuggets = nuggets;
};
