import store from "@/store/index.js";

export const get_worksheets = (state) => state.worksheets;
export const get_worksheet = (state) => state.worksheet;
export const get_worksheet_rounds = (state) => state.worksheet_rounds;
export const get_next_question = (state) => {
  if (!state.worksheet_rounds || !state.worksheet) return null;
  let rounds = [...state.worksheet_rounds];
  let user_id = state.worksheet.turn_id;
  let next_question = null;
  let current_question_id = state.current_question_id;
  let current_round_id = state.current_round_id;
  if (!current_round_id) rounds = rounds.sort((a, b) => a.order - b.order);
  if (current_round_id) {
    let idx = rounds.findIndex(function (round) {
      return round.id === current_round_id;
    });
    let round = { ...rounds[idx] };
    rounds.splice(idx, 1);
    rounds.unshift(round);
  }
  rounds.forEach((r) => {
    let answers = r.answers[user_id];
    answers.forEach((a) => {
      if (
        !a.is_finished &&
        a.question_id !== current_question_id &&
        !next_question
      ) {
        next_question = { question_id: a.question_id, round_id: r.id };
        return false;
      }
      if (next_question) return false;
    });
  });
  return next_question;
};
export const get_current_category = (state) => state.current_category;
export const get_navigation_visible = (state) => state.navigation_visible;
export const get_worksheet_progress = (state) => {
  if (!state.worksheet) return 0;
  let worksheet = { ...state.worksheet };
  let rounds = [...state.worksheet_rounds];
  let questions_total = worksheet.question_count * worksheet.round_count;
  let questions_finished = state.progress_triggered ? 1 : 0;
  let user_id = store.getters["auth/get_current_user"].id;
  rounds.forEach((r) => {
    r.answers[user_id].forEach((a) => {
      if (a.is_finished) questions_finished++;
    });
  });
  return Math.round((questions_finished / questions_total) * 100);
};
