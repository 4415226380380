import { QuizResult } from "@/models/quiz/results/QuizResult";

export class QuizDuelResult extends QuizResult {
  constructor(obj) {
    super(obj);
    this.type = "duel";
    this.user_count = 2;

    this.rounds = this.generateRounds();
  }

  generateRounds() {
    if (!this.quiz_rounds) return [];

    let rounds = [...this.quiz_rounds];
    rounds.forEach((round) => {
      var winner = null;
      var score_user = 0;
      var score_time = 0;
      var score_opponent = 0;
      var score_opponent_time = 0;

      if (typeof round.winner_id === "number") {
        this.user_id === round.winner_id
          ? (winner = this.user)
          : (winner = this.opponent);
      }

      if (round.answers[this.user.id]) {
        round.answers[this.user.id].forEach((answer) => {
          score_user += answer.blix_correct;
          score_time += answer.blix_time;
        });
      }
      if (round.answers[this.opponent.id]) {
        round.answers[this.opponent.id].forEach((answer) => {
          score_opponent += answer.blix_correct;
          score_opponent_time += answer.blix_time;
        });
      }

      round.winner = winner;
      round.score_user = score_user;
      round.score_time = score_time;
      round.score_opponent = score_opponent;
      round.score_opponent_time = score_opponent_time;
    });
    return rounds;
  }
}
