export const set_register_userinfo = (state, user) =>
  (state.register_userinfo = user);
export const set_register_code = (state, code) => (state.register_code = code);
export const set_register_invitation_token = (state, token) =>
  (state.register_invitation_token = token);
export const set_register_custom_fields = (state, custom_fields) =>
  (state.register_custom_fields = custom_fields);
export const set_register_assign_structures = (state, structures) =>
  (state.register_assign_structures = structures);
export const set_register_select_structures = (state, structures) =>
  (state.register_select_structures = structures);
export const set_current_user = (state, user) => (state.current_user = user);
export const set_auth_sources = (state, s) => (state.auth_sources = s);
