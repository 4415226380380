import { Notification } from "@/models/notifications/Notification";
// achievement:
// Number id
// String img_url
// Number price
// Number stage
// String type (?)

export class NotificationAchievementUnlocked extends Notification {
  constructor(obj) {
    super(obj);
    this.achievement = obj.achievement_stage;
    this.link = {
      name: "AchievementDetail",
      params: { id: obj.achievement_stage.achievement_id },
    };
    this.image = obj.achievement_stage.img_url;
    this.background_image = "not-achievement-unlocked.png";
  }
}
