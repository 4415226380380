<template>
  <div id="notifications">
    <v-menu
      v-if="$vuetify.breakpoint.smAndUp"
      v-model="visible"
      left
      bottom
      absolute
      offset-overflow
      tile
      transition="slide-x-transition"
      :close-on-click="true"
      :close-on-content-click="false"
      :min-width="$vuetify.breakpoint.xsOnly ? '95%' : 400"
      max-width="500"
      max-height="85vh"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon small v-bind="attrs" v-on="on">
          <NotificationIcon :color="iconColor" />
        </v-btn>
      </template>

      <NotificationList v-if="visible" @close="visible = false" />
    </v-menu>

    <div v-if="$vuetify.breakpoint.xsOnly">
      <v-btn dark icon small :to="{ name: 'Notifications' }">
        <NotificationIcon :color="iconColor" />
      </v-btn>

      <!-- <div
        class="smallScreen elevation-2"
        :class="{ 'visible' : visible }"
      >
        <NotificationList
          @close="visible = false"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import NotificationIcon from "@/components/notifications/NotificationIcon";
import NotificationList from "@/components/notifications/NotificationList";

export default {
  name: "NotificationHandler",
  components: {
    NotificationIcon,
    NotificationList,
  },
  props: {
    iconColor: {
      type: String,
      required: false,
      default: "white",
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      hide_seen: false,
    };
  },
  watch: {
    visible(state) {
      if (state) this.fetchData();
    },
    $route() {
      this.visible = false;
    },
  },
  methods: {
    ...mapActions("notifications", ["fetch_notifications"]),
    async fetchData() {
      this.loading = true;
      if (this.visible) await this.fetch_notifications();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
/* .smallScreen {
	width: 100vw;
	position: absolute;
	background: var(--v-background-base);
	top: -100vh;
	left: 0;
	z-index: 9999 !important;
	transition: 1s;
}
.smallScreen.visible {
	top: 0;
	transition: 1s;
} */
</style>
