export const ICONS = {
  // NAVIGATION
  menu: {
    mdi: "mdi-dots-vertical",
    fa: "fa fa-ellipsis-vertical",
    custom: "menu.svg",
  },
  "menu-x": {
    mdi: "mdi-dots-horizontal",
    fa: "fa fa-ellipsis",
    custom: "menu-x.svg",
  },
  "arrow-left": {
    mdi: "mdi-arrow-left",
    fa: "fa fa-arrow-left",
    custom: "arrow-left.svg",
  },
  "arrow-right": {
    mdi: "mdi-arrow-right",
    fa: "fa fa-arrow-right",
    custom: "arrow-right.svg",
  },
  "arrow-left-bold": {
    mdi: "mdi-arrow-left-bold",
    fa: "fa fa-circle-arrow-left",
    custom: "arrow-left-bold.svg",
  },
  "arrow-right-bold": {
    mdi: "mdi-arrow-right-bold",
    fa: "fa fa-circle-arrow-right",
    custom: "arrow-right-bold.svg",
  },
  "chevron-left": {
    mdi: "mdi-chevron-left",
    fa: "fa fa-chevron-left",
    custom: "chevron-left.svg",
  },
  "chevron-right": {
    mdi: "mdi-chevron-right",
    fa: "fa fa-chevron-right",
    custom: "chevron-right.svg",
  },
  "chevron-up": {
    mdi: "mdi-chevron-up",
    fa: "fa fa-chevron-up",
    custom: "chevron-up.svg",
  },
  "chevron-down": {
    mdi: "mdi-chevron-down",
    fa: "fa fa-chevron-down",
    custom: "chevron-down.svg",
  },
  "menu-left": {
    mdi: "mdi-menu-left",
    fa: "fa fa-caret-left",
    custom: "menu-left.svg",
  },
  "menu-right": {
    mdi: "mdi-menu-right",
    fa: "fa fa-caret-right",
    custom: "menu-right.svg",
  },
  close: {
    mdi: "mdi-close",
    fa: "fa fa-xmark",
    custom: "close.svg",
  },
  "close-circle": {
    mdi: "mdi-close-circle",
    fa: "fa fa-circle-xmark",
    custom: "close-circle.svg",
  },
  check: {
    mdi: "mdi-check",
    fa: "fa fa-check",
    custom: "check.svg",
  },
  "check-circle": {
    mdi: "mdi-check-circle",
    fa: "fa fa-circle-check",
    custom: "check-circle.svg",
  },
  "percent-circle": {
    mdi: "mdi-percent-circle",
    fa: "fa fa-percent",
    custom: "percent-circle.svg",
  },
  "list-bulleted": {
    mdi: "mdi-format-list-bulleted",
    fa: "fa fa-list",
    custom: "list-bulleted.svg",
  },
  replay: {
    mdi: "mdi-replay",
    fa: "fa fa-rotate-left",
    custom: "replay.svg",
  },
  info: {
    mdi: "mdi-information",
    fa: "fa fa-circle-info",
    custom: "info.svg",
  },
  help: {
    mdi: "mdi-help",
    fa: "fa fa-question",
    custom: "help.svg",
  },
  "help-circle": {
    mdi: "mdi-help-circle",
    fa: "fa fa-circle-question",
    custom: "help-circle.svg",
  },
  send: {
    mdi: "mdi-send-circle",
    fa: "fa fa-circle-right",
    custom: "send.svg",
  },
  plus: {
    mdi: "mdi-plus",
    fa: "fa fa-plus",
    custom: "plus.svg",
  },
  minus: {
    mdi: "mdi-minus",
    fa: "fa fa-minus",
    custom: "minus.svg",
  },
  alert: {
    mdi: "mdi-alert",
    fa: "fa fa-triangle-exclamation",
    custom: "alert.svg",
  },
  "alert-variant": {
    mdi: "mdi-alert-decagram",
    fa: "fa fa-circle-exclamation",
    custom: "alert-variant.svg",
  },
  swap: {
    mdi: "mdi-swap-horizontal-bold",
    fa: "fa fa-right-left",
    custom: "swap.svg",
  },
  notification: {
    mdi: "mdi-bell",
    fa: "fa fa-bell",
    custom: "notification.svg",
  },
  "notification-active": {
    mdi: "mdi-bell-ring",
    fa: "fa fa-bell",
    custom: "notification-active.svg",
  },
  timer: {
    mdi: "mdi-timer-sand",
    fa: "fa fa-hourglass",
    custom: "timer.svg",
  },
  "timer-full": {
    mdi: "mdi-timer-sand-full",
    fa: "fa fa-hourglass-end",
    custom: "timer-full.svg",
  },
  settings: {
    mdi: "mdi-cogs",
    fa: "fa fa-gears",
    custom: "settings.svg",
  },
  tune: {
    mdi: "mdi-tune",
    fa: "fa fa-sliders",
    custom: "tune.svg",
  },
  login: {
    mdi: "mdi-login-variant",
    fa: "fa fa-arrow-right-to-bracket",
    custom: "login.svg",
  },
  logout: {
    mdi: "mdi-logout-variant",
    fa: "fa fa-arrow-right-from-bracket",
    custom: "logout.svg",
  },
  support: {
    mdi: "mdi-face-agent",
    fa: "fa fa-headset",
    custom: "support.svg",
  },
  sale: {
    mdi: "mdi-sale",
    fa: "fa fa-burst",
    custom: "sale.svg",
  },
  search: {
    mdi: "mdi-magnify",
    fa: "fa fa-magnifying-glass",
    custom: "search.svg",
  },

  // FORMS & INTERACTIONS
  checkbox: {
    mdi: "mdi-checkbox-blank-outline",
    fa: "fa fa-square",
    custom: "checkbox.svg",
  },
  "checkbox-marked": {
    mdi: "mdi-checkbox-marked-outline",
    fa: "fa fa-square-check",
    custom: "checkbox-marked.svg",
  },
  edit: {
    mdi: "mdi-pencil",
    fa: "fa fa-pencil",
    custom: "edit.svg",
  },
  delete: {
    mdi: "mdi-delete",
    fa: "fa fa-trash",
    custom: "delete.svg",
  },
  copy: {
    mdi: "mdi-content-copy",
    fa: "fa fa-copy",
    custom: "copy.svg",
  },
  share: {
    mdi: "mdi-share",
    fa: "fa fa-share",
    custom: "share.svg",
  },
  like: {
    mdi: "mdi-thumb-up",
    fa: "fa fa-thumbs-up",
    custom: "like.svg",
  },
  bookmark: {
    mdi: "mdi-bookmark",
    fa: "fa fa-bookmark",
    custom: "bookmark.svg",
  },
  locked: {
    mdi: "mdi-lock",
    fa: "fa fa-lock",
    custom: "locked.svg",
  },
  unlocked: {
    mdi: "mdi-lock-open",
    fa: "fa fa-lock-open",
    custom: "unlocked.svg",
  },
  "keyboard-backspace": {
    mdi: "mdi-backspace",
    fa: "fa fa-delete-left",
    custom: "keyboard-backspace.svg",
  },
  "keyboard-space": {
    mdi: "mdi-keyboard-space",
    fa: "fa fa-square-full",
    custom: "keyboard-space.svg",
  },
  document: {
    mdi: "mdi-file-document",
    fa: "fa fa-file-lines",
    custom: "document.svg",
  },
  "list-status": {
    mdi: "mdi-list-status",
    fa: "fa fa-list-check",
    custom: "list-status.svg",
  },
  fullscreen: {
    mdi: "mdi-fullscreen",
    fa: "fa fa-expand",
    custom: "fullscreen.svg",
  },

  // MISC
  star: {
    mdi: "mdi-star",
    fa: "fa fa-star",
    custom: "star.svg",
  },
  friends: {
    mdi: "mdi-account-heart",
    fa: "fa fa-people-line",
    custom: "friends.svg",
  },
  results: {
    mdi: "mdi-clipboard-list",
    fa: "fa fa-clipboard-list",
    custom: "results.svg",
  },
  "results-info": {
    mdi: "mdi-text-box-search-outline",
    fa: "fa fa-rectangle-list",
    custom: "results-info.svg",
  },
  quiz: {
    mdi: "mdi-lightbulb-question",
    fa: "fa fa-lightbulb",
    custom: "quiz.svg",
  },
  ranking: {
    mdi: "mdi-podium",
    fa: "fa fa-ranking-star",
    custom: "ranking.svg",
  },
  quest: {
    mdi: "mdi-calendar-check",
    fa: "fa fa-calendar-check",
    custom: "quest.svg",
  },
  training: {
    mdi: "mdi-dumbbell",
    fa: "fa fa-dumbbell",
    custom: "training.svg",
  },
  duel: {
    mdi: "mdi-fencing",
    fa: "fa fa-people-arrows-left-right",
    custom: "duel.svg",
  },
  "head-question": {
    mdi: "mdi-head-question",
    fa: "fa fa-person-circle-plus",
    custom: "head-question.svg",
  },
  "user-question": {
    mdi: "mdi-account-question",
    fa: "fa fa-person-circle-question",
    custom: "user-question.svg",
  },
  trophy: {
    mdi: "mdi-trophy",
    fa: "fa fa-trophy",
    custom: "trophy.svg",
  },
  award: {
    mdi: "mdi-trophy-award",
    fa: "fa fa-award",
    custom: "award.svg",
  },
  "award-questions": {
    mdi: "mdi-email-seal",
    fa: "fa fa-medal",
    custom: "award-questions.svg",
  },
  stats: {
    mdi: "mdi-finance",
    fa: "fa fa-chart-line",
    custom: "stats.svg",
  },
  subscriptions: {
    mdi: "mdi-email-fast",
    fa: "fa fa-envelopes-bulk",
    custom: "subscriptions.svg",
  },
  dashboard: {
    mdi: "mdi-view-dashboard",
    fa: "fab fa-buromobelexperte",
    custom: "dashboard.svg",
  },
  explore: {
    mdi: "mdi-library",
    fa: "fa fa-book-open-reader",
    custom: "explore.svg",
  },
  play: {
    mdi: "mdi-play",
    fa: "fa fa-play",
    custom: "play.svg",
  },
  community: {
    mdi: "mdi-account-group",
    fa: "fa fa-people-group",
    custom: "community.svg",
  },
  learn: {
    mdi: "mdi-school",
    fa: "fa fa-graduation-cap",
    custom: "learn.svg",
  },
  categories: {
    mdi: "mdi-shape",
    fa: "fa fa-shapes",
    custom: "categories.svg",
  },
  user: {
    mdi: "mdi-account",
    fa: "fa fa-user",
    custom: "user.svg",
  },
  users: {
    mdi: "mdi-account-supervisor",
    fa: "fa fa-user-group",
    custom: "users.svg",
  },
  certificate: {
    mdi: "mdi-shield-star",
    fa: "fa fa-shield",
    custom: "shield-star.svg",
  },

  // BRANDS
  windows: {
    mdi: "mdi-microsoft-windows",
    fa: "fab fa-windows",
    custom: "windows.svg",
  },
};
