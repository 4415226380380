import { Notification } from "@/models/notifications/Notification";
// game:
// Number id
// Obj opponent
// Number opponent_roundwins
// Number user_roundwins
// Number turn_id

export class NotificationDuelFinished extends Notification {
  constructor(obj) {
    super(obj);
    this.game = obj.game;
    this.link = { name: "ResultsQuiz", params: { quiz_id: obj.game.id } };
    this.image = obj.game.opponent.img_url;
    this.avatar_border =
      obj.game.opponent_roundwins > obj.game.user_roundwins
        ? "warning"
        : "success";
  }
}
