export const set_journeys = (state, journeys) => {
  state.journeys = journeys;
};

export const set_journey = (state, journey) => {
  state.journey = journey;
};

/* export const set_journey_user = (state, journeyUser) => {
	state.journeyUser = journeyUser;
} */
