import store from "@/store/index.js";
import { Achievement } from "@/models/achievements/Achievement.js";
export class User {
  id = -1; // required
  username = ""; // required
  level = -1; // required
  level_percent = -1;
  avatar_img = ""; // required
  achievements = [];
  last_duels = [];
  open_duel = null;
  open_duel_invitation = null;
  stats = null; // correct_questions_percent, duel_win_percent, duels, finished_journeys, played_questions, submitted_questions
  user_type = -1; // 0 player 1 bot
  can_duel = false; // can play against auth, bot is always true
  allow_profile = false;
  allow_friend = false;

  constructor(obj) {
    this.id = obj.id;
    this.username = obj.username;
    this.level = obj.level;
    this.level_percent = obj.level_percent;
    this.avatar_img = obj.avatar_img;
    this.achievements = obj.achievements
      ? obj.achievements.map((achievement) => {
          return new Achievement(achievement);
        })
      : [];
    this.last_duels = obj.last_duels;
    this.open_duel = obj.open_duel;
    this.open_duel_invitation = obj.open_duel_invitation;
    this.stats = obj.stats;
    this.user_type = obj.user_type;
    this.can_duel = obj.can_duel;
    this.allow_profile =
      obj.settings && obj.settings.allow_profile
        ? obj.settings.allow_profile
        : false;
    this.allow_friends =
      obj.settings && obj.settings.allow_friends
        ? obj.settings.allow_friends
        : false;
  }

  get name() {
    return this.username;
  }

  get is_friend() {
    return store.getters["auth/get_current_user"].friends.includes(this.id);
  }

  get is_bot() {
    return this.user_type === 1;
  }

  async request_friendship() {
    let payload = {
      user_id: this.id,
    };
    let res = await await store.dispatch(
      "users/add_friendship_invitation",
      payload
    );

    if (res._status === 200) {
      store.commit("app/set_alert", {
        type: "success",
        title: "views.subscriptions.success",
        text: "resources.friends.add_invitation_success",
      });
      store.dispatch("users/fetch_friendship_invitations");
      store.dispatch("users/fetch_user", this.id);
    } else {
      store.commit("app/set_alert", { type: "error", title: "toasters.error" });
    }
  }

  async remove_friendship() {
    let payload = {
      user_id: this.id,
    };
    let res = await store.dispatch("users/delete_friendship", payload);

    if (res._status === 200) {
      store.commit("app/set_alert", {
        type: "success",
        title: "views.subscriptions.success",
        text: "resources.friends.remove_friend_success",
      });
      store.dispatch("auth/get_user", { root: true });
    } else {
      store.commit("app/set_alert", { type: "error", title: "toasters.error" });
    }
  }
}
