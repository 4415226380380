<template>
  <div
    class="d-flex justify-center align-center py-1"
    :class="`mb-${marginBottom}`"
  >
    <v-spacer v-if="centered" />
    <h3
      class="text--body text-uppercase font-weight-light"
      :class="[`${textColor}--text ${textOptions}`, customClass]"
      style="line-height: auto"
    >
      <span>{{ text }}</span>
    </h3>
    <v-spacer />
    <slot name="actions" />
  </div>
</template>

<script>
export default {
  name: "CustomTitle",
  props: {
    text: {
      type: [String, Number],
      required: true,
    },
    customClass: {
      type: [String],
      required: false,
      default: "",
    },
    textColor: {
      type: String,
      required: false,
      default: "text",
    },
    textOptions: {
      type: String,
      Number,
      required: false,
      default: "",
    },
    iconColor: {
      type: String,
      required: false,
      default: "text",
    },
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
    marginBottom: {
      type: [String, Number],
      required: false,
      default: 1,
    },
  },
};
</script>
