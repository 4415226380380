import { Question } from "@/models/questions/Question";
import i18n from "@/i18n";

export class QuestionMultiChoice extends Question {
  init(obj) {
    super.init(obj);

    this.question_type = "multi_choice";

    if (!obj) {
      this.answers = [
        {
          body: "",
          body_format: "text",
          id: this.getRandomId(),
          is_correct: true,
          options: null,
          order: 0,
        },
        {
          body: "",
          body_format: "text",
          id: this.getRandomId(),
          is_correct: false,
          options: null,
          order: 1,
        },
      ];
    }
  }

  get formRules() {
    return {
      body: [
        this.body.length > 0 || i18n.t("ng.add_chars_hint"),
        this.body.length <= 255 ||
          i18n.t("ng.too_many_chars_hint", {
            count: this.body.length,
            max: 255,
          }),
      ],
      hint: [
        this.hint.length <= 255 ||
          i18n.t("ng.too_many_chars_hint", {
            count: this.hint.length,
            max: 255,
          }),
      ],
      answer: [
        (v) => (!!v && v.length > 0) || i18n.t("ng.add_chars_hint"),
        (v) =>
          (!!v && v.length <= 255) ||
          i18n.t("ng.too_many_chars_hint", { count: v.length, max: 255 }),
      ],
      answers: [
        this.hasCorrectAnswer() ||
          i18n.t("ng.multi_choice_hint", { count: this.correctAnswerCount() }),
      ],
      categories: [
        this.category_ids.length > 0 || i18n.t("ng.select_category"),
      ],
      explanation: [
        (v) => {
          if (v)
            return (
              v.length <= 512 ||
              i18n.t("ng.too_many_chars_hint", { count: v.length, max: 512 })
            );
          else return true;
        },
      ],
    };
  }

  changeIsCorrect(answer, value) {
    let idx = this.answers.indexOf(answer);
    /* if(value) {
      this.answers.forEach(a => { return a.is_correct = false })
    }  */
    this.answers[idx].is_correct = value;
  }

  canBeActivated() {
    if (this.correctAnswerCount() < 1) return false;
    return true;
  }
}
