<template>
  <div class="d-flex align-center">
    <v-icon
      v-if="iconSet === 'mdi'"
      :color="color"
      :large="large"
      :small="small"
      :x-small="xSmall"
    >
      {{ icons[icon][iconSet] }}
    </v-icon>

    <font-awesome-icon
      v-if="iconSet === 'fa'"
      :icon="icons[icon][iconSet]"
      :color="customIconColor"
      :size="faSize"
    />

    <svg
      v-if="iconSet === 'custom'"
      :width="svgSize[0]"
      :height="svgSize[1]"
      :style="{ color: `${customIconColor} !important` }"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <defs>
        <mask :id="`fillMask-${random}`" x="0" y="0" width="100" height="100">
          <image
            :xlink:href="`/cdn/custom_icons/${icons[icon][iconSet]}`"
            x="0"
            y="0"
            width="100"
            height="100"
          />
        </mask>
      </defs>
      <rect
        x="0"
        y="0"
        width="100"
        height="100"
        style="stroke: none; fill: currentColor"
        :mask="`url(&quot;#fillMask-${random}&quot;)`"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "PIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "text",
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    iconSet() {
      //return 'fa'
      return process.env.VUE_APP_ICON_SET;
    },
    random() {
      return Math.floor(Math.random() * 1000000);
    },
    faSize() {
      //"xs", "lg", "sm", "1x", "2x", "3x", "4x", "5x", "6x", "7x", "8x", "9x", "10x"
      if (this.large) return "2x";
      if (this.small) return "1x";
      if (this.xSmall) return "xs";
      return "lg";
    },
    svgSize() {
      if (this.large) return [32, 32];
      if (this.small) return [20, 20];
      if (this.xSmall) return [16, 16];
      return [24, 24];
    },
    icons() {
      return this.$icons;
    },
    customIconColor() {
      try {
        let colorArr = this.color.split(" ");
        let color = colorArr[0];
        let variant = colorArr[1];
        let hex = "#ff0000";
        if (
          color.includes("primary") ||
          color.includes("secondary") ||
          color.includes("accent") ||
          color.includes("success") ||
          color.includes("error") ||
          color.includes("warning") ||
          color.includes("info")
        ) {
          if (!variant) {
            hex = this.$vuetify.theme.themes.light[color].base;
          } else {
            if (variant === "lighten-1")
              hex = this.$vuetify.theme.themes.light[color].lighten1;
            if (variant === "lighten-2")
              hex = this.$vuetify.theme.themes.light[color].lighten2;
            if (variant === "lighten-3")
              hex = this.$vuetify.theme.themes.light[color].lighten3;
            if (variant === "darken-1")
              hex = this.$vuetify.theme.themes.light[color].darken1;
            if (variant === "darken-2")
              hex = this.$vuetify.theme.themes.light[color].darken2;
            if (variant === "darken-3")
              hex = this.$vuetify.theme.themes.light[color].darken3;
          }
        } else return this.color;
        return hex;
      } catch (e) {
        console.error("Unknown color", this.color);
        return "red";
      }
    },
  },
};
</script>
