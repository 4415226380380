import API from "@/plugins/servers/Api.js";
import { Report } from "@/models/content/Report.js";
import { QuestionEstimation } from "@/models/questions/types/QuestionEstimation.js";
import { QuestionGap } from "@/models/questions/types/QuestionGap.js";
import { QuestionMultiChoice } from "@/models/questions/types/QuestionMultiChoice.js";
import { QuestionSingleChoice } from "@/models/questions/types/QuestionSingleChoice.js";

// REPORTS
export const report_question = async (_, payload) => {
  try {
    let [res] = await API.post(
      `/ng_questions/${payload.question_id}/report`,
      null,
      payload
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_report = async ({ commit }, id) => {
  try {
    let [res] = await API.get(`/ng_question_reports/${id}`);
    if (res) {
      commit("set_report", new Report(res.report));
    } else return null;
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_reports = async ({ commit }) => {
  try {
    let [res] = await API.get("/ng_question_reports/");
    if (res._status === 200) {
      let reports = res.reports.map((report) => {
        return new Report(report);
      });
      commit("set_reports", reports);
    } else return null;
  } catch (err) {
    return err;
  }
};

export const add_report_comment = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.post(
      `/ng_question_reports/${payload.report_id}/comments`,
      null,
      payload
    );
    if (res._status === 200) dispatch("fetch_report", payload.report_id);
    return res;
  } catch (err) {
    return err;
  }
};

export const delete_comment = async (_, id) => {
  try {
    let [res] = await API.delete(`/ng_question_comments/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const submit_question = async (_, payload) => {
  try {
    let [res] = await API.post("/ng_questions/", null, payload);
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_user_categories = async ({ commit }) => {
  // TODO: separate endpoint?
  try {
    let [res] = await API.get("/quizzes/categories");
    commit("set_user_categories", res.categories);
    return res;
  } catch (err) {
    return err;
  }
};

export const fetch_questions = async ({ commit }) => {
  try {
    let [res] = await API.get("/ng_questions/");
    if (res._status === 200) {
      let questions = [];
      res.questions.forEach((question) => {
        if (question.question_type === "estimation") {
          questions.push(new QuestionEstimation(question));
          return false;
        }
        if (question.question_type === "single_choice") {
          questions.push(new QuestionSingleChoice(question));
          return false;
        }
        if (question.question_type === "multi_choice") {
          questions.push(new QuestionMultiChoice(question));
          return false;
        }
        if (question.question_type === "gap_text") {
          questions.push(new QuestionGap(question));
          return false;
        }
        console.error("unknown question type");
      });
      commit("set_questions", questions);
    } else return null;
  } catch (err) {
    return err;
  }
};

export const fetch_question = async ({ commit }, id) => {
  try {
    let [res] = await API.get(`/ng_questions/${id}`);
    if (res._status === 200) {
      if (res.question.question_type === "estimation") {
        commit("set_question", new QuestionEstimation(res.question));
        return false;
      }
      if (res.question.question_type === "single_choice") {
        commit("set_question", new QuestionSingleChoice(res.question));
        return false;
      }
      if (res.question.question_type === "multi_choice") {
        commit("set_question", new QuestionMultiChoice(res.question));
        return false;
      }
      if (res.question.question_type === "gap_text") {
        commit("set_question", new QuestionGap(res.question));
        return false;
      }
    } else return null;
    return res;
  } catch (err) {
    return err;
  }
};

export const add_question_comment = async ({ dispatch }, payload) => {
  try {
    let [res] = await API.post(
      `/ng_questions/${payload.question_id}/comments`,
      null,
      payload
    );
    if (res._status === 200) dispatch("fetch_question", payload.question_id);
    return res;
  } catch (err) {
    return err;
  }
};

export const add_answer = async ({ dispatch }, { payload }) => {
  try {
    let [res] = await API.post("question_answers/", null, payload);
    if (res._status === 200) {
      dispatch("fetch_question", payload.question_id);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_question = async ({ dispatch }, { payload }) => {
  try {
    let [res] = await API.patch(`ng_questions/${payload.id}`, null, payload);
    if (res._status === 200) {
      dispatch("fetch_question", payload.id);
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const patch_answer = async ({ dispatch }, { payload }) => {
  try {
    let [res] = await API.patch(
      `question_answers/${payload.id}`,
      null,
      payload
    );
    if (res._status === 200) {
      dispatch("fetch_question", payload.questionId);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const delete_question = async (_, id) => {
  try {
    let [res] = await API.delete(`ng_questions/${id}`);
    return res;
  } catch (err) {
    return false;
  }
};

export const delete_answer = async ({ dispatch }, { payload }) => {
  try {
    let [res] = await API.delete(`question_answers/${payload.id}`);
    if (res._status === 200) {
      dispatch("fetch_question", payload.question_id);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};
