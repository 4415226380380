import { Notification } from "@/models/notifications/Notification";
// quest:
// Number id
// String img_url
// Number prize
// String type (?)

export class NotificationQuestUnlocked extends Notification {
  constructor(obj) {
    super(obj);
    this.quest = obj.quest;
    this.link = { name: "Quests" };
    this.avatar_border = "accent";
  }
}
