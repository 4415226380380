<template>
  <v-card elevation="0" :color="color">
    <v-card-text class="text-center">
      <div class="text--header-l my-0">
        {{ value }}
      </div>
      <div class="text--caption">
        {{ description }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "StatsItemSimple",
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "transparent",
    },
  },
};
</script>

<style></style>
