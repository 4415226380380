import { Notification } from "@/models/notifications/Notification";
// reward (reward_item):
// Number id
// Number reward_id (category)
// String img_url

export class NotificationRewardUnlocked extends Notification {
  constructor(obj) {
    super(obj);
    this.reward = obj.reward_item;
    this.no_avatar = true;
    this.background_image =
      this.reward.reward_id === 1
        ? "not-reward-unlocked-voucher.png"
        : "not-reward-unlocked-avatar.png";
  }
}
