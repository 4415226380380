import { Notification } from "@/models/notifications/Notification";
// game:
// Number id
// Obj opponent
// Number opponent_roundwins
// Number user_roundwins
// Number turn_id

export class NotificationDuelTurn extends Notification {
  constructor(obj) {
    super(obj);
    this.game = obj.game;
    this.link = {
      name: "QuizPlay",
      params: {
        quiz_id: obj.game.id,
        type: "duel",
        opponent_id: obj.game.opponent.id,
      },
    };
    this.image = obj.game.opponent.img_url;
  }
}
