export class Quiz {
  constructor(obj) {
    this.id = obj.id;
    this.quiz_type = obj.quiz_type;
    this.turn_id = obj.turn_id;
    this.user_count = obj.user_count;
    this.is_finished = obj.is_finished;

    this.question_count = obj.question_count;
    this.question_source = obj.question_source;

    this.round_count = obj.round_count;
    this.quiz_rounds = this.generateRounds(obj.quiz_rounds); // QuizRound
    this.results = this.generateResults(obj.results);

    this.options = obj.options;
    this.answers = obj.answers;
  }

  // TODO: needed?
  generateRounds(rounds) {
    return rounds;
    /* let quiz_rounds = [];
		rounds.forEach(round => quiz_rounds.push(new QuizRound(round)));
		return quiz_rounds; */
  }

  generateResults(results) {
    return results;
    /* let quiz_results = [];
		results.forEach(result => quiz_results.push(new QuizResult(result)));
		return quiz_results; */
  }
}
