export const set_quiz = (state, quiz) => (state.quiz = quiz);
export const set_quiz_feedback = (state, feedback) =>
  (state.quiz_feedback = feedback);

export const set_quiz_result = (state, result) => (state.quiz_result = result);
export const set_quiz_results = (state, results) =>
  (state.quiz_results = results);
export const set_quiz_result_answer = (state, answer) =>
  (state.quiz_result_answer = answer);
export const set_quiz_open_games = (state, quizzes) =>
  (state.quiz_open_games = quizzes);

export const set_quiz_categories = (state, categories) =>
  (state.quiz_categories = categories);
export const set_quiz_opponent = (state, opponent) =>
  (state.quiz_opponent = opponent);
export const set_quiz_info = (state, quiz) => (state.quiz_info = quiz);

export const set_quiz_opponents = (state, opponents) =>
  (state.quiz_opponents = opponents);
