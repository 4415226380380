export class WorksheetRound {
  constructor(obj, user) {
    this.id = obj.id;
    this.user_id = user;

    this.quiz_id = obj.quiz_id;
    this.worksheet_id = obj.quiz_id;
    this.category_id = obj.category_id;
    this.category = obj.category ? obj.category : null;

    this.is_finished = obj.is_finished;
    this.is_initialized = obj.is_initialized;

    this.answers = obj.answers; // todo: reorder

    this.order = obj.order;

    // needed?
    this.winner_id = obj.winner_id;
  }

  get questions() {
    return this.answers[this.user_id];
  }
}
