import { Notification } from "@/models/notifications/Notification";
// question:
// Number id

export class NotificationQuestionRevision extends Notification {
  constructor(obj) {
    super(obj);
    this.question = obj.question;
    this.link = { name: "QuestionDetail", params: { id: obj.question.id } };
    this.background_image = "not-question-edit.png";
    this.no_avatar = true;
  }
}
