import * as types from "./mutation-types";
import * as actions from "./actions";

const state = {
  shorterTutorial: true,
};

const getters = {
  shorterTutorial: (state) => state.shorterTutorial,
};

const mutations = {
  [types.SET_SHORTER_TUTORIAL](state, params) {
    state.shorterTutorial = params;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
